import axios from "axios";

export default axios.create({
  // localhost
  baseURL: process.env.VUE_APP_BASE_URL,
  // baseURL: "http://localhost:3000/api",
  // baseURL: "https://poker-v1-backend-production.up.railway.app/api",
  // baseURL: "https://railway-test-nem-production.up.railway.app/api",
  // baseURL: "https://poker-v3-backend-production.up.railway.app/api",
  
  headers: {
    "Content-type": "application/json"
  }
});