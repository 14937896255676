import http from "../http-common";

class StartsidaDataService {
  
  getAllPub() {
    return http.get("/startsidapub");
  }
  get(id) {
    return http.get(`/startsida/${id}`);
  }
  update(id, data) {
    return http.put(`/startsida/${id}`, data);
  }




// används nedan ?
  getAll() {
    return http.get("/startsida");
  }

  create(data) {
    return http.post("/startsida", data);
  }

  
  delete(id) {
    return http.delete(`/startsida/${id}`);
  }

  deleteAll() {
    return http.delete(`/startsida`);
  }

  findByTitle(title) {
    return http.get(`/startsida?title=${title}`);
  }
}

export default new StartsidaDataService();