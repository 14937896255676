import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';

// import { ValidationProvider } from 'vee-validate/dist/vee-validate.full';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas);

import { faPen } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faPen)
import moment from 'moment-timezone'
moment.tz.setDefault('Europe/Stockholm')

createApp(App)
  .use(router)
  .use(store)
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount("#app");