<template>
  <div>
  <!-- <div id="app"> -->
    <nav class="container navbar navbar-expand-lg navbar-light bg-light py-3">
      <div class="container-fluid">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" 
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item"><router-link v-if="!currentUser" to="/home" class="nav-link"> Pokerhaket</router-link></li>
            <li class="nav-item"><router-link v-if="currentUser" to="/startsida" class="nav-link"> Pokerhaket</router-link></li>
            <li class="nav-item"><router-link v-if="currentUser" to="/spelningar" class="nav-link">Spelningar</router-link></li>
            <li class="nav-item"> <router-link v-if="currentUser" to="/resultat" class="nav-link">Resultat</router-link></li>
            <li class="nav-item"><router-link v-if="currentUser" to="/ranking" class="nav-link">Ranking</router-link></li>
            <li class="nav-item"><router-link v-if="!currentUser" to="/login" class="nav-link"> Login</router-link></li>
            <li class="nav-item"><router-link v-if="currentUser" to="/profile" class="nav-link">
              <font-awesome-icon icon="user" />{{ currentUser.username }}</router-link>
            </li>
            <li class="nav-item" v-if="currentUser">
              <a class="nav-link" @click.prevent="logOut">
                Logga ut
              </a></li>
            </ul>
          </div>
        </div>
      </nav>
      <nav v-if="showModeratorBoard || showAdminBoard" class="container navbar navbar-expand-lg navbar-dark bg-dark py-3">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContentAdmin"
        aria-controls="navbarSupportedContentAdmin" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      
      <div class="collapse navbar-collapse" id="navbarSupportedContentAdmin">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item"><router-link v-if="showModeratorBoard || showAdminBoard" to="/startsida_admin" class="nav-link">Startsida</router-link></li>
          <li class="nav-item"><router-link v-if="showModeratorBoard || showAdminBoard" to="/board_admin" class="nav-link">Meddelanden</router-link></li>
          <li class="nav-item"><router-link v-if="showAdminBoard || showAdminBoard" to="/threads" class="nav-link">Forum</router-link></li>
          <!-- <li class="nav-item"> <router-link v-if="showAdminBoard" to="/posts" class="nav-link">Forum</router-link></li>
            <li class="nav-item"><router-link v-if="showAdminBoard" to="/post_admin" class="nav-link">Forum</router-link></li> -->
            <li class="nav-item"><router-link v-if="showModeratorBoard || showAdminBoard" to="/usersimple" class="nav-link">Användare</router-link></li>
            <li class="nav-item"><router-link v-if="showModeratorBoard || showAdminBoard" to="/spelningar_admin" class="nav-link">Spelningar</router-link></li>
            <li class="nav-item"><router-link v-if="showModeratorBoard || showAdminBoard" to="/resultat_admin" class="nav-link">Resultat</router-link></li>
            <li class="nav-item"><router-link v-if="showModeratorBoard || showAdminBoard" to="/ranking_admin" class="nav-link">Ranking</router-link></li>
          </ul>
          <div class="navbar-nav mr-auto navbar-custom">

          </div>
        </div>
      </nav>
    <div class="container">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser['roles']) {
        return this.currentUser['roles'].includes('ROLE_ADMIN');
      }

      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser['roles']) {
        return this.currentUser['roles'].includes('ROLE_MODERATOR');
      }

      return false;
    }
  },
  methods: {
    logOut() {
      this.$router.push('/');
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    }
  },
  mounted() {
    console.log(`Working on '${process.env.VUE_APP_MODE}' mode`)
    console.log(`Working on '${process.env.VUE_APP_BASE_URL}' url`)
  },
};
</script>

<style>
/* @import "~bulma/css/bulma.css"; */
/* @import "https://cdn.jsdelivr.net/npm/bulma@0.9.4/css/bulma.min.css"; */

#nav {
  padding: 2px;
  text-align: center;
}

#nav a {
  font-weight: bold;
  color: red;
}

/* is-active router-link-exact-active nav-link */
.navbar-nav .active a {
  color: red;
  
}

#nav a.router-link-exact-active {
  color: whitesmoke;
  background: crimson;
  border-radius: 1rem;

}
nav.navbar a.active {
    background-color: hsl(0deg, 0%, 98%);
    /* color: #FA7C91; */
}
.navbar-custom {
  height: 2.5rem;
}

hr {
   background-color: #0b0a0a !important;
}

.list {
    text-align: left;
    width: 100%;
    margin: auto;
  }

a {
  text-decoration: none !important;
  }

a:hover {
  text-decoration: underline !important;
  }

a.router-link-exact-active {
  /* color: whitesmoke; */
  /* color: whitesmoke; */
  /* background: crimson; */
  color: red;
  border-bottom: 3px solid red;
}

  /* body {
    background-color: #3c896d;
} */

/* .card-link .own {
    color: #3c896d;
}
.navbar-light .navbar-nav a.nav-link {
    color: #3c896d;
}

.navbar-light .navbar-nav a.nav-link:hover {
    color: #db5461;
}

.navbar-light .navbar-nav .nav-item .nav-link {
    color: #db5461;
} */



</style>