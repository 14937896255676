import axios from 'axios';
import authHeader from './auth-header';

// const API_URL = 'http://localhost:3000/api/test/';
const API_URL = process.env.VUE_APP_API_URL_TEST;
// localhost
// const API_URL = 'https://poker-v1-backend-production.up.railway.app/api/test/';
// const API_URL = 'https://poker-railway-backend-production.up.railway.app/api/test/';
// const API_URL = 'https://railway-test-nem-production.up.railway.app/api/test/';
// const API_URL = 'https://poker-v3-backend-production.up.railway.app/api/test/';

class UserService {
  getPublicContent() {
    return axios.get(API_URL + 'all');
  }

  // anävnds nedan ?

  getUserBoard() {
    return axios.get(API_URL + 'user', { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(API_URL + 'mod', { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + 'admin', { headers: authHeader() });
  }
}

export default new UserService();