<template>
    <div class="container">
      <div v-if="startsidas.length">
        <div v-for="(startsida, index) in startsidas" :key="index">
          <div class="col-md-10">
            <div v-if="startsida.published">
              <div id="meddelande" class="pasted-startmed" v-html="startsida.meddelande">
              </div>
            </div>
            <div v-if="!startsida.published">
              Ingen startsida text publiserad
            </div>
          </div>
        </div>
      </div>
      <div v-if="!startsidas.length">
        <h3>
          Välkommen till PokerHaket !!
        </h3>
        <br />
        <p>
          Ansök om medlemskap via epost, info[at]pokerhaket.se
        </p>
      </div>      
    </div>
  </template>
  
  <script>
  import UserService from "../services/user.service";
  import StartsidaDataService from "../services/StartsidaDataService.js";
  
  export default {
    name: "HomePage",
    data() {
      return {
        content: "",
        startsidas: [],
        meddelande: "",
      };
    },
    mounted() {
      UserService.getPublicContent().then(
        (response) => {
          this.content = response.data;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
      this.retrievePosts();
    },
    methods: {
      retrievePosts() {
        console.log("retrievePosts");
        StartsidaDataService.getAllPub()
          .then(response => {
            this.startsidas = response.data;
          })
          .catch(e => {
            console.log(e);
          });
      },
    },
  };
  </script>

<style scoped>

.pasted-startmed :deep(div) {
  width: 100% !important;
}

</style>