import { createWebHistory, createRouter } from "vue-router";
import Home from "./components/Home.vue";
import Login from "./components/Login.vue";
import Register from "./components/Register.vue";
// lazy-loaded
const StartsidaEdit = () => import("./components/StartsidaEdit.vue")
const BoardList = () => import("./components/BoardList.vue")
const BoardListAdmin = () => import("./components/BoardListAdmin.vue")
const AddBoard = () => import("./components/AddBoard.vue")
const BoardEdit = () => import("./components/BoardEdit.vue")
const SpelningarList = () => import("./components/SpelningarList.vue")
const SpelningDetail = () => import("./components/SpelningDetail.vue")
const SpelningarListAdmin = () => import("./components/SpelningarListAdmin.vue")
const AddSpelning = () => import("./components/AddSpelning.vue")
const SpelningEdit = () => import("./components/SpelningEdit.vue")
const AddResultat = () => import("./components/AddResultat.vue")
const ResultatList = () => import("./components/ResultatList.vue")
const ResultatDetail = () => import("./components/ResultatDetail.vue")
const ResultatListAdmin = () => import("./components/ResultatListAdmin.vue")
const ResultatEdit = () => import("./components/ResultatEdit.vue")
const RankingList = () => import("./components/RankingList.vue")
const RankingListAdmin = () => import("./components/RankingListAdmin.vue")
const AddRanking = () => import("./components/AddRanking.vue")
const RankingEdit = () => import("./components/RankingEdit.vue")
const Profile = () => import("./components/Profile.vue")
const ProfileSimpleDetail = () => import("./components/ProfileSimpleDetail.vue")
const ProfileSimpleDetailPass = () => import("./components/ProfileSimpleDetailPass.vue")
const UserSimpleList = () => import("./components/UserSimpleList.vue")
const UserSimpleDetail = () => import("./components/UserSimpleDetail.vue")
const UserSimpleDetailPass = () => import("./components/UserSimpleDetailPass.vue")
const ThreadList = () => import("./components/ThreadList.vue")
const ThreadDetail = () => import("./components/ThreadDetail.vue")
const AddPost = () => import("./components/AddPost.vue")
const AddThread = () => import("./components/AddThread.vue")

const NotFoundPage = () => import("./components/NotFoundPage.vue")





// används nedan ?
const PostList = () => import("./components/PostList.vue")
const PostEdit = () => import("./components/PostEdit.vue")
// const PostDetail = () => import("./components/PostDetail.vue")
const PostListAdmin = () => import("./components/PostListAdmin.vue")


const StartsidaListAdmin = () => import("./components/StartsidaListAdmin.vue")
const BoardAdmin = () => import("./components/BoardAdmin.vue")
const BoardModerator = () => import("./components/BoardModerator.vue")
const BoardUser = () => import("./components/BoardUser.vue")
const AddSpelningKopia = () => import("./components/AddSpelningKopia.vue")
const AddResultatBlank = () => import("./components/AddResultatBlank.vue")
const KategorierList = () => import("./components/KategorierList.vue")
const AddKategori = () => import("./components/AddKategori.vue")
const KategoriEdit = () => import("./components/KategoriEdit.vue")
const KategoriDetail = () => import("./components/KategoriDetail.vue")
const AnmalanList = () => import("./components/AnmalanList.vue")
const AddTutorial = () => import("./components/AddTutorial.vue")
const TutorialsList = () => import("./components/TutorialsList.vue")
const TutorialDetail = () => import("./components/TutorialDetail.vue")
const TagList = () => import("./components/TagList.vue")
const TagDetail = () => import("./components/TagDetail.vue")
const AddTag = () => import("./components/AddTag.vue")
const SpelareList = () => import("./components/SpelareList.vue")
const AddSpelare = () => import("./components/AddSpelare.vue")
// const SpelareEdit = () => import("./components/SpelareEdit.vue")
const SpelareDetail = () => import("./components/SpelareDetail.vue")
const RankingDetail = () => import("./components/RankingDetail.vue")

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/home",
    component: Home,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/register",
    component: Register,
  },
  {
    path: "/startsida_admin",
    name: "startsidaadmin",
    // lazy-loaded
    component: StartsidaListAdmin,
  },
  {
    path: "/startsidaedit/:id",
    name: "startsida-editera",
    // lazy-loaded
    component: StartsidaEdit,
  },
  {
    path: "/startsida",
    name: "boards",
    // lazy-loaded
    component: BoardList,
  },
  {
    path: "/board_admin",
    name: "boardadmin",
    // lazy-loaded
    component: BoardListAdmin,
  },
  {
    path: "/addboard",
    name: "add-board",
    // lazy-loaded
    component: AddBoard,
  },
  {
    path: "/boardedit/:id",
    name: "board-editera",
    // lazy-loaded
    component: BoardEdit,
  },
  {
    path: "/spelningar",
    name: "spelningar",
    // lazy-loaded
    component: SpelningarList,
  },
  {
    path: "/spelningar/:id",
    name: "spelning-details",
    // lazy-loaded
    component: SpelningDetail,
  },
  {
    path: "/spelningar_admin",
    name: "spelningaradmin",
    // lazy-loaded
    component: SpelningarListAdmin,
  },
  {
    path: "/add",
    name: "add-spelning",
    // lazy-loaded
    component: AddSpelning,
  },
  {
    path: "/spelningedit/:id",
    name: "spelning-editera",
    // lazy-loaded
    component: SpelningEdit,
  },
  {
    path: "/addresultat/:id",
    name: "add-resultat",
    // lazy-loaded
    component: AddResultat,
  },
  {
    path: "/resultat",
    name: "resultat",
    // lazy-loaded
    component: ResultatList,
  },
  {
    path: "/resultat/:id",
    name: "resultat-details",
    // lazy-loaded
    component: ResultatDetail,
  },
  {
    path: "/resultat_admin",
    name: "resultatadmin",
    // lazy-loaded
    component: ResultatListAdmin,
  },
  {
    path: "/resultatedit/:id",
    name: "resultat-editera",
    // lazy-loaded
    component: ResultatEdit,
  },
  {
    path: "/ranking",
    name: "ranking",
    // lazy-loaded
    component: RankingList,
  },
  {
    path: "/ranking_admin",
    name: "rankingadmin",
    // lazy-loaded
    component: RankingListAdmin,
  },
  {
    path: "/addranking",
    name: "add-ranking",
    // lazy-loaded
    component: AddRanking,
  },
  {
    path: "/rankingedit/:id",
    name: "ranking-editera",
    // lazy-loaded
    component: RankingEdit,
  },
  {
    path: "/profile",
    name: "profile",
    // lazy-loaded
    component: Profile,
  },
  {
    path: "/profilesimpledetail/:id",
    name: "profilesimple-details",
    // lazy-loaded
    component: ProfileSimpleDetail,
  },
  {
    path: "/profilesimpledetailpass/:id",
    name: "profilesimplepass-details",
    // lazy-loaded
    component: ProfileSimpleDetailPass,
  },
  {
    path: "/usersimple",
    name: "usersimple",
    // lazy-loaded
    component: UserSimpleList,
  },
  {
    path: "/usersimple/:id",
    name: "usersimple-details",
    // lazy-loaded
    component: UserSimpleDetail,
  },
  {
    path: "/usersimplepass/:id",
    name: "usersimplepass-details",
    // lazy-loaded
    component: UserSimpleDetailPass,
  },
  {
    path: "/threads",
    name: "threads",
    // lazy-loaded
    component: ThreadList,
  },
  {
    path: "/threads/:id",
    name: "thread-details",
    // lazy-loaded
    component: ThreadDetail,
  },
  {
    path: "/addpost/:id",
    name: "add-post",
    // lazy-loaded
    component: AddPost,
  },
  {
    path: "/addthread",
    name: "add-thread",
    // lazy-loaded
    component: AddThread,
  },









  // används nedan ?

  {
    path: "/admin",
    name: "admin",
    // lazy-loaded
    component: BoardAdmin,
  },
  {
    path: "/mod",
    name: "moderator",
    // lazy-loaded
    component: BoardModerator,
  },
  {
    path: "/user",
    name: "user",
    // lazy-loaded
    component: BoardUser,
  },





    {
    path: "/add/:id",
    name: "add-spelningkopia",
    // lazy-loaded
    component: AddSpelningKopia,
  },


  {
    path: "/addresultatblank",
    name: "add-resultatblank",
    // lazy-loaded
    component: AddResultatBlank,
  },






  {
    path: "/ranking/:id",
    name: "ranking-details",
    // lazy-loaded
    component: RankingDetail,
  },

  {
    path: "/kategorier",
    name: "kategorier",
    // lazy-loaded
    component: KategorierList,
  },
  {
    path: "/kategorier/:id",
    name: "kategori-details",
    // lazy-loaded
    component: KategoriDetail,
  },
  {
    path: "/kategorigedit/:id",
    name: "kategori-editera",
    // lazy-loaded
    component: KategoriEdit,
  },
  {
    path: "/addkategori",
    name: "add-kategori",
    // lazy-loaded
    component: AddKategori,
  },
  {
    path: "/anmalan",
    name: "anmalan-list",
    // lazy-loaded
    component: AnmalanList,
  },
  {
    path: "/addtutorial",
    name: "add-tutorial",
    // lazy-loaded
    component: AddTutorial,
  },
  {
    path: "/tutorials",
    name: "tutorials",
    // lazy-loaded
    component: TutorialsList,
  },
  {
    path: "/tutorials/:id",
    name: "tutorial-details",
    // lazy-loaded
    component: TutorialDetail,
  },
  {
    path: "/tag",
    name: "tag",
    // lazy-loaded
    component: TagList,
  },
  {
    path: "/tag/:id",
    name: "tag-details",
    // lazy-loaded
    component: TagDetail,
  },
  {
    path: "/addtag",
    name: "add-tag",
    // lazy-loaded
    component: AddTag,
  },
  {
    path: "/spelare",
    name: "spelare",
    // lazy-loaded
    component: SpelareList,
  },
  {
    path: "/spelare/:id",
    name: "spelare-details",
    // lazy-loaded
    component: SpelareDetail,
  },
  // {
    //   path: "/spelareedit/:id",
    //   name: "spelare-editera",
    //   // lazy-loaded
    //   component: SpelareEdit,
    // },
    {
      path: "/addspelare",
      name: "add-spelare",
      // lazy-loaded
      component: AddSpelare,
    },





  {
    path: "/posts",
    name: "posts",
    // lazy-loaded
    component: PostList,
  },

  {
    path: "/postedit/:id",
    name: "post-editera",
    // lazy-loaded
    component: PostEdit,
  },
  {
    path: "/post_admin",
    name: "postadmin",
    // lazy-loaded
    component: PostListAdmin,
  },
 





  

   // will match everything and put it under `$route.params.pathMatch`
  { path: '/:pathMatch(.*)*', name: 'NotFoundPage', component: NotFoundPage },
  // will match anything starting with `/user-` and put it under `$route.params.afterUser`
  { path: '/user-:afterUser(.*)', component: NotFoundPage },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: 'is-active',
});

router.beforeEach((to, from, next) => {
    // const publicPages = ['/login', '/register', '/home'];
    const publicPages = ['/home', '/login'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');

      // trying to access a restricted page + not logged in
      // redirect to login page
  if (authRequired && !loggedIn) {
    // next('/login');
    next('/home');
  } else {
    next();
  }
});

export default router;